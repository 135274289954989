﻿.footer-link {
	@include font-footer-link;
	width: 100%;
	text-align: center;
	text-decoration: none;
	font-weight: 900;
	text-transform: uppercase;

	.footer-link__text {
		@include underline-effect-base(#fff, -2px, 4px);

		&:hover {
			@include underline-effect-hover();
		}
	}
}
