﻿.highlighted-case {

	.highlighted-case__image-title-container {
		position: relative;
		display: flex;
		z-index: 0;
		min-height: rem(600px);
		padding: rem(40px) rem(20px);

		@include breakpoint(t) {
			min-height: rem(700px);
			padding: rem(50px) rem(40px);
		}

		@include breakpoint(l) {
			min-height: rem(800px);
			padding: rem(60px) rem(60px);
		}

		.highlighted-case__title-content {
			margin-top: auto;
		}

		.title-background {
			margin: 0 0 rem(36px);
		}

		.sub-title {
			@include font-image-overview-caption;
			@include fade-in-right(0.25s);
		}

		.title > span {
			@include fade-in-right(0.5s);
		}

		.button {
			@include fade-in-right(0.75s);
		}
	}

	.highlighted-case__image {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
		opacity: 0.5;
		transition: opacity $transition-time ease;

		.js & {
			opacity: 0;
		}

		@at-root .js .animate#{&} {
			opacity: 0.5;
		}
	}

	.highlighted-case__text-container {
		@include fade-in-up(0.75s);
		position: relative;
		text-align: center;
		padding: rem(116px) 0 rem(77px);

		&:after {
			@include icon($icon-arrow-up);
			position: absolute;
			top: rem(60px);
			left: 50%;
			transform: translateX(-50%);
			font-size: rem(11px);
		}
	}

	.highlighted-case__link {
		display: block;

		.title-background {

			.sub-title,
			.title > span {
				position: relative;
				z-index: 0;
				overflow: hidden;

				&:before {
					position: absolute;
					z-index: -1;
					content: '';
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					background: $color-pink;
					transform: scaleX(0);
					transform-origin: left;
					transition: transform $transition-time ease;
					transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
				}
			}

			.sub-title {
				z-index: 1;
			}

			.title > span {

				@for $i from 1 through 10 {

					&:nth-child(#{$i}):before {
						transition-delay: (0.1s * $i);
					}
				}
			}
		}

		&:hover {

			.highlighted-case__image {
				opacity: 0.75 !important;
			}

			.title-background {

				.sub-title,
				.title > span {

					&:before {
						transform: scaleX(1);
					}
				}
			}
		}
	}
}

@keyframes fade-in-case {

	0% {
		opacity: 0;
	}

	100% {
		opacity: 0.5;
	}
}