﻿@mixin colored-background($backgroundUrl: "../../images/colored-background/colored-background", $is-png: true, $position: center top) {

	&:before {
		@include fade-in($delay: 0s);
		position: absolute;
		z-index: -2;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: $position;

		@if $is-png {
			background-image: url(#{$backgroundUrl}-432.png);
		}
		@else {
			background-image: url(#{$backgroundUrl}-432.jpg);
		}
	}

	@include breakpoint(ml) {

		&:before {
			@if $is-png {
				background-image: url(#{$backgroundUrl}-768.png);
			}
			@else {
				background-image: url(#{$backgroundUrl}-768.jpg);
			}
		}
	}

	@include breakpoint(t) {

		&:before {
			@if $is-png {
				background-image: url(#{$backgroundUrl}-1024.png);
			}
			@else {
				background-image: url(#{$backgroundUrl}-1024.jpg);
			}
		}
	}

	@include breakpoint(l) {

		&:before {
			@if $is-png {
				background-image: url(#{$backgroundUrl}-1280.png);
			}
			@else {
				background-image: url(#{$backgroundUrl}-1280.jpg);
			}
		}
	}

	@include breakpoint(lm) {

		&:before {
			@if $is-png {
				background-image: url(#{$backgroundUrl}-1440.png);
			}
			@else {
				background-image: url(#{$backgroundUrl}-1440.jpg);
			}
		}
	}

	@include breakpoint(ll) {

		&:before {
			@if $is-png {
				background-image: url(#{$backgroundUrl}-1920.png);
			}
			@else {
				background-image: url(#{$backgroundUrl}-1920.jpg);
			}
		}
	}

	@include breakpoint(d) {

		&:before {
			@if $is-png {
				background-image: url(#{$backgroundUrl}-2560.png);
			}
			@else {
				background-image: url(#{$backgroundUrl}-2560.jpg);
			}
		}
	}
}
