﻿.contact-information {
	@include font-description;
	text-transform: uppercase;
	font-weight: bold;
	text-align: right;
	width: 100%;

	@include breakpoint(l) {
		text-align: left;
		width: auto;
	}

	.contact-inforamtion__contact-details {
		margin-bottom: rem(39px);
	}

	.contact-inforamtion__link {
		@include underline-effect-base(#fff, 0, 1px);

		&:hover {
			@include underline-effect-hover();
		}
	}

	.social-media {
		display: flex;
		justify-content: flex-end;
		margin: 0 rem(-10px);

		@include breakpoint(l) {
			display: block;
		}
	}
}
