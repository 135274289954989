﻿// Gotham
@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-Light.woff2) format('woff2'), url(../../fonts/Montserrat-Light.woff) format('woff');
	font-weight: 300;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-Regular.woff2) format('woff2'), url(../../fonts/Montserrat-Regular.woff) format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-Italic.woff2) format('woff2'), url(../../fonts/Montserrat-Italic.woff) format('woff');
	font-weight: 400;
	font-style: italic;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-Bold.woff2) format('woff2'), url(../../fonts/Montserrat-Bold.woff) format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-BoldItalic.woff2) format('woff2'), url(../../fonts/Montserrat-BoldItalic.woff) format('woff');
	font-weight: 700;
	font-style: italic;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-ExtraBold.woff2) format('woff2'), url(../../fonts/Montserrat-ExtraBold.woff) format('woff');
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Montserrat';
	src: local('Montserrat'), url(../../fonts/Montserrat-ExtraBoldItalic.woff2) format('woff2'), url(../../fonts/Montserrat-ExtraBoldItalic.woff) format('woff');
	font-weight: 800;
	font-style: italic;
	font-stretch: normal;
	font-display: swap;
}

@mixin font-text {
	font-family: $base-font-family;
	font-size: rem(24px);
	line-height: rem(37px);
	font-weight: normal;

	@include breakpoint(ts) {
		font-size: rem(30px);
		line-height: rem(46px);
	}
}

@mixin font-legal {
	font-size: rem(12px);
	line-height: rem(24px);
}

@mixin font-header-menu {
	font-size: rem(14px);
	line-height: rem(24px);
	letter-spacing: 0.175em;
}

@mixin font-button {
	font-size: rem(16px);
	line-height: rem(16px);
	letter-spacing: 0.12em;
}

@mixin font-description {
	font-size: rem(16px);
	line-height: rem(24px);
}

@mixin font-image-overview-caption {
	font-size: rem(18px);
	line-height: rem(24px);
}

@mixin font-sub-title {
	font-size: rem(24px);
	line-height: rem(24px);
}

@mixin font-image-overview-title {
	font-size: rem(24px);
	line-height: rem(24px);
}

@mixin font-slide {
	font-size: rem(32px);
	line-height: rem(32px);
}

@mixin font-footer-link {
	font-size: rem(48px);
	line-height: rem(48px);
}

@mixin font-title-small {
	font-size: rem(55px);
	line-height: rem(55px);

	@include breakpoint(t) {
		font-size: rem(65px);
		line-height: rem(65px);
	}
}

@mixin font-fader-title {
	font-size: rem(55px);
	line-height: rem(55px);

	@include breakpoint(l) {
		font-size: rem(66px);
		line-height: rem(66px);
	}

	@include breakpoint(lm) {
		font-size: rem(77px);
		line-height: rem(77px);
	}

	@include breakpoint(ll) {
		font-size: rem(88px);
		line-height: rem(88px);
	}
}

@mixin font-side-menu {
	font-size: rem(50px);
	line-height: rem(60px);

	@include breakpoint(t) {
		font-size: rem(65px);
		line-height: rem(78px);
	}

	@include breakpoint(l) {
		font-size: rem(80px);
		line-height: rem(96px);
	}
}

@mixin font-header-title {
	font-size: rem(60px);
	line-height: rem(60px);

	@include breakpoint(t) {
		font-size: rem(75px);
		line-height: rem(75px);
	}

	@include breakpoint(l) {
		font-size: rem(90px);
		line-height: rem(90px);
	}
}

@mixin font-title {
	font-size: rem(60px);
	line-height: rem(60px);

	@include breakpoint(t) {
		font-size: rem(80px);
		line-height: rem(80px);
	}

	@include breakpoint(l) {
		font-size: rem(100px);
		line-height: rem(100px);
	}

	@include breakpoint(lm) {
		font-size: rem(120px);
		line-height: rem(120px);
	}
}