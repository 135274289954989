﻿.pagination-container {
	text-align: center;
	padding: rem(31px) 0 0;

	.page-link {
		@include font-description;
		font-weight: 800;
		text-transform: uppercase;
		font-style: italic;
		@include underline-effect-base(#fff, -2px);

		&:hover {
			@include underline-effect-hover();
		}
	}
}
