﻿.fader {

	.swiper-container-horizontal > .swiper-pagination {
		@include fade-in-up($delay: 0s);
		z-index: 2;
		width: auto;
		bottom: rem(15px);
		left: rem(20px);
		line-height: 0;

		@include breakpoint(l) {
			bottom: rem(20px);
			left: calc(50% + 6rem); //96px
		}
	}

	.fader__link-container {
		@include fade-in-up($delay: 0s);
		position: absolute;
		z-index: 1;
		width: 100%;
		max-width: rem(640px);
		padding: 0 rem(20px);
		bottom: 0;
		left: 0;
		text-align: right;

		@include breakpoint(l) {
			right: 0;
			left: auto;
		}

		@include breakpoint(ll) {
			right: auto;
			left: calc(50% + 4.75rem); //76px
		}
	}

	.fader__link {
		@include font-button;
		font-weight: 800;
		font-style: italic;
		text-transform: uppercase;
		@include underline-effect-base(#fff, -2px);

		&:hover {
			@include underline-effect-hover();
		}
	}

	.swiper-slide {
		height: auto;
		background: #000;

		@include breakpoint(ts) {
			padding: 0 0 rem(46px);
		}

		@include breakpoint(l) {
			padding: 0;
		}
	}
}

.fader-item {
	position: relative;
	z-index: 0;
	background: #000;
	padding: 0 0 rem(40px);

	@include breakpoint(l) {
		padding: 0;
	}

	.columns {
		align-items: center;
	}

	.column:first-child {
		@include fade-in-up($delay: 0s);
		padding: 0 rem(20px);

		@include breakpoint(l) {
			@include fade-in-right($delay: 0s);
		}
	}

	.column:last-child {
		@include fade-in-up($delay: 0s);

		@include breakpoint(l) {
			@include fade-in-left($delay: 0s);
		}
	}

	.fader-item__container {
		margin: 0 0 rem(32px);

		@include breakpoint(ts) {
			position: absolute;
			z-index: 1;
			width: 100%;
			margin: 0;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.title-background {
		width: 100%;
		max-width: rem(768px);

		@include breakpoint(l) {
			margin: 0 0 0 auto;
		}

		.sub-title {
			overflow: hidden;
			background: transparent;
			padding: 0;

			> span {
				display: block;
				transform: translateY(100%);
				background: #000;
				transition: transform 0.5s ease;
				padding: rem(11px) rem(17px) rem(10px);

				.swiper-slide-active &,
				.swiper-slide-duplicate-active & {
					transform: translateY(0);
					transition: transform 0.5s ease 1s;
				}
			}
		}

		.title {
			@include font-fader-title;

			> span {
				overflow: hidden;
				background: transparent;
				padding: 0;

				> span {
					transform: translateY(100%);
					transition: transform 0.5s ease;
					background: #000;

					.swiper-slide-active &,
					.swiper-slide-duplicate-active & {
						transform: translateY(0);
						transition: transform 0.5s ease 1s;
					}
				}
			}
		}
	}

	.content {
		@include font-image-overview-caption;
		line-height: rem(32px);
		overflow: hidden;

		p, ul, ol {
			transform: translateY(125%);
			transition: transform 0.5s ease;
			margin: 0 0 rem(30px);

			.swiper-slide-active &,
			.swiper-slide-duplicate-active & {
				transform: translateY(0);
				transition: transform 0.5s ease 1s;
			}
		}
	}

	.button-container {
		overflow: hidden;

		.button {
			transform: translateY(125%);
			transition: transform 0.5s ease;

			.swiper-slide-active &,
			.swiper-slide-duplicate-active & {
				transform: translateY(0);
				transition: transform 0.5s ease 1s;
			}
		}
	}

	.fader-item__content {
		max-width: rem(640px);
		padding: 0 rem(20px);

		@include breakpoint(l) {
			margin: 0 0 0 rem(76px);
		}
	}

	.image {
		@include fade-in-up();
		max-width: rem(840px);

		@include breakpoint(ts) {
			clip-path: polygon(0 0, 100% 0, calc(100% - 123px) 100%, 0 100%);
			opacity: 0.5;
		}

		@include breakpoint(l) {
			@include fade-in-right();
			opacity: 1;
			max-width: 50%;
		}
	}
}