﻿.nav.is-side-nav {
	@include font-side-menu;
	margin: 0 0 rem(50px) auto;
	text-transform: uppercase;
	font-weight: 700;
	text-align: right;

	@include breakpoint(l) {
		margin: 0 0 0 auto;
	}

	.nav__list {
		margin-right: -10px;

		@include breakpoint(l) {
			margin-right: 0;
		}
	}

	a {
		z-index: 1;
		position: relative;
		padding: 0 rem(20px);
		transition: color $transition-time ease-in-out;

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: #fff;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform $transition-time ease-in-out;
			transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
		}

		&:hover {
			color: $color-purple;

			&:before {
				transform: scaleX(1);
				transform-origin: right;
			}
		}
	}
}
