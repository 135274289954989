﻿.overview-item {
	@include fade-in-up;
	position: relative;
	display: block;
	z-index: 0;
	margin: 0 0 rem(6px);

	.image {
		z-index: -1;

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			background: linear-gradient(270deg, $color-purple 0%, $color-pink 100%);
			opacity: 0;
			transition: opacity $transition-time ease;
		}
	}

	.overview-item__title {
		@extend %title-background;
		@extend .title-background-is-black;
		@extend .is-small;
		position: absolute;
		bottom: rem(20px);
		left: rem(20px);
		z-index: 1;
	}

	.overview-item__title {

		.title-background {
			line-height: 0;
			margin: 0;
		}

		.sub-title {
			@include font-image-overview-caption;
			padding: 0 rem(4px);
			transition: background $transition-time ease;
		}

		.title {
			@include font-slide;
			padding: rem(1px) 0 rem(1px);
			margin: rem(-1px) 0;

			span {
				transition: background $transition-time ease;
			}
		}
	}

	.button {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		opacity: 0;
		transition: background $transition-time ease, color $transition-time ease, opacity $transition-time ease;
	}

	&:hover {

		.overview-item__title {

			.sub-title,
			.title span {
				background: none;
			}
		}

		.image:after {
			opacity: 0.9;
		}

		.button {
			opacity: 1;
		}
	}
}
