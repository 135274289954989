﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-slide {
		@include responsive-container-psudo(802, 810);
	}

	&.is-case {
		@include responsive-container-psudo(1176, 662);
	}

	&.is-overview-item {
		@include responsive-container-psudo(600, 338);
	}

	&.is-fader {
		@include responsive-container-psudo(840, 560);
	}

	img {
		width: 100%;
		height: auto;
	}
}
