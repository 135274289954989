﻿.content-block {
	position: relative;
	z-index: 0;
	background: #000;

	.title,
	.title-background {
		margin: 0 0 rem(43px);
	}

	&.is-white {
		background: #fff;
		color: #000;
		padding: rem(160px) 0;

		+ .is-white {
			padding-top: 0;

			&.is-case-block {

				.container {

					@include breakpoint(l) {
						margin-top: rem(-80px);
					}
				}
			}
		}
	}

	&.is-black {
		padding: rem(160px) 0;

		+ .is-black {
			padding-top: 0;
		}
	}

	&.is-colored {
		overflow: hidden;
		padding: rem(160px) 0;
		background: linear-gradient(135deg, $color-purple 10%, $color-pink 100%);
		font-weight: 300;

		@extend %background-lines;

		+ .is-colored {
			padding-top: 0;
		}
	}

	&.is-text-block {
		padding: rem(121px) 0 rem(82px);

		.content {
			margin-top: rem(9px);
		}

		.button:last-child {
			margin-bottom: rem(58px);
		}

		.column:first-child {
			@include fade-in-down();
		}

		.column:last-child {
			@include fade-in-down();
		}

		&.has-no-columns {
			text-align: center;

			.title {
				@include fade-in-down();
			}

			.content {
				@include fade-in-down();
			}

			.button {
				@include fade-in-down();
			}
		}

		@include breakpoint(l) {
			padding: rem(141px) 0 rem(102px);

			&:nth-child(even) {

				.column:first-child {
					order: 1;
					text-align: left;
					@include fade-in-left();
				}

				.column:last-child {
					order: 0;
					text-align: right;
					@include fade-in-right();
				}
			}

			&:nth-child(odd) {

				.column:first-child {
					text-align: right;
					@include fade-in-right();
				}

				.column:last-child {
					text-align: left;
					@include fade-in-left();
				}
			}
		}
	}

	&.is-case-block {
		//padding: rem(121px) 0 rem(82px);
		@include breakpoint(l) {
			//padding: rem(221px) 0 rem(182px);
			&:nth-child(even) {

				.content-block__content {
					@include fade-in-right(0.25s);
				}

				.image,
				.video {
					clip-path: polygon(132px 0, 0 100%, 100% 100%, 100% 0);
				}
			}

			&:nth-child(odd) {

				.content-block__content {
					@include fade-in-left(0.25s);
				}

				.image,
				.video {
					clip-path: polygon(0 0, 0 100%, calc(100% - 132px) 100%, 100% 0);
				}
			}
		}

		@extend %title-background;

		.container {

			@include breakpoint(l) {
				position: absolute;
				z-index: 1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				pointer-events: none;
			}
		}

		.content-block__content {
			@include fade-in-left();
			position: relative;
			z-index: 1;
			pointer-events: initial;
			padding: 0 0 rem(50px);

			@include breakpoint(l) {
				width: 60%;
				padding: 0;
			}

			@include breakpoint(lm) {
				width: 50%;
			}
		}

		.image {

			@include breakpoint(l) {
				max-width: 62%;
			}

			@include breakpoint(d) {
				//max-width: 72%;
			}
		}

		.video {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}

		.image,
		.video {
			@include fade-in-right();

			@include breakpoint(l) {
				@include fade-in();
			}
		}

		&:nth-child(even) {

			.image {

				@include breakpoint(l) {
					margin: 0 0 0 auto;
				}
			}
		}

		&:nth-child(odd) {

			.content-block__content {

				@include breakpoint(l) {
					left: calc(50% - 6rem); //96px
				}
			}
		}

		&.is-black {

			@extend .is-black;
		}

		&.is-colored {
			background: linear-gradient(135deg, $color-purple 0%, $color-pink 100%);

			.title span,
			.sub-title {
				background: #000;
			}
		}

		.title-video-link {

			.title-background {

				.sub-title,
				.title > span {
					position: relative;
					z-index: 0;
					overflow: hidden;

					&:before {
						position: absolute;
						z-index: -1;
						content: '';
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background: $color-pink;
						transform: scaleX(0);
						transform-origin: left;
						transition: transform $transition-time ease;
						transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
					}
				}

				.sub-title {
					z-index: 1;
				}

				.title > span {

					@for $i from 1 through 10 {

						&:nth-child(#{$i}):before {
							transition-delay: (0.1s * $i);
						}
					}
				}
			}

			&:hover .title-background {

				.sub-title,
				.title > span {

					&:before {
						transform: scaleX(1);
					}
				}
			}
		}
	}

	&.is-call-to-action {
		@include colored-background();
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: rem(210px) 0 rem(122px);

		.content-block__content {
			@include fade-in-right();
			padding: 0 rem(20px);
			margin: auto 0 0;

			@include breakpoint(t) {
				padding: 0 rem(65px);
			}
		}

		.content-block__background-image {
			@include fade-in;
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		&.has-image {
			@include fade-in();
			min-height: rem(600px);
			padding: rem(210px) 0 rem(60px);
			align-items: flex-end;

			@include breakpoint(t) {
				min-height: rem(700px);
			}

			@include breakpoint(l) {
				min-height: rem(800px);
			}
		}

		.title-background {
			@include fade-in-right(0.25s);
		}

		.button {
			@include fade-in-right(0.5s);
		}

		.content-block__link {
			display: block;

			.title-background {

				.sub-title,
				.title > span {
					position: relative;
					z-index: 0;
					overflow: hidden;

					&:before {
						position: absolute;
						z-index: -1;
						content: '';
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						background: $color-pink;
						transform: scaleX(0);
						transform-origin: left;
						transition: transform $transition-time ease;
						transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
					}
				}

				.sub-title {
					z-index: 1;
				}

				.title > span {

					@for $i from 1 through 10 {

						&:nth-child(#{$i}):before {
							transition-delay: (0.1s * $i);
						}
					}
				}
			}

			&:hover .title-background {

				.sub-title,
				.title > span {

					&:before {
						transform: scaleX(1);
					}
				}
			}
		}
	}

	&.is-text-block-with-image {
		padding: rem(121px) 0 0;
		overflow: hidden;

		.content-block__content {
			padding: 0 rem(20px) rem(82px);
		}

		.image {
			max-width: rem(768px);
			margin-bottom: rem(82px);

			@include breakpoint(t) {
				clip-path: polygon(0 0, 0 100%, calc(100% - 132px) 100%, 100% 0);
			}

			@include breakpoint(l) {
				//margin-bottom: 0;
			}

			@include breakpoint(d) {
				max-width: rem(1088px);
			}
		}

		@include breakpoint(l) {
			padding: rem(221px) 0 rem(182px);

			.content-block__content {
				padding: 0;
				max-width: rem(647px);
			}

			.columns {
				align-items: center;
			}

			&:nth-child(even) {

				.image {
					clip-path: polygon(132px 0, 0 100%, 100% 100%, 100% 0);
					margin: 0 0 0 auto;
				}

				.content-block__content {
					padding: 0 0 0 rem(20px);
					margin: 0 0 0 auto;
				}

				.column:first-child {
					@include fade-in-left();
				}

				.column:last-child {
					@include fade-in-right();
				}
			}

			&:nth-child(odd) {

				.content-block__content {
					padding: 0 rem(20px) 0 0;
				}

				.column:first-child {
					order: 1;
					@include fade-in-right();
				}

				.column:last-child {
					order: 0;
					@include fade-in-left();
				}
			}
		}
	}

	&.is-media-oveview {
		padding: rem(95px) 0 rem(134px);

		h2.title {
			@include fade-in-up;
			text-align: center;
			margin: 0 0 rem(51px);
		}

		@include breakpoint(l) {
			padding: rem(95px) 0 rem(134px);
			//padding: rem(221px) 0 rem(182px);
		}
	}

	&.is-slider-block {
		padding-bottom: rem(274px);
		overflow: hidden;

		.title {
			@include fade-in-up;
		}

		.container.is-smaller {
			text-align: center;

			.content {
				overflow: hidden;
				margin: 0 0 rem(62px);
			}
		}

		.swiper-slide {

			@for $i from 1 through 10 {

				&:nth-child(#{$i}) {
					@include fade-in-down($i * 0.08s);
				}
			}
		}

		.swiper-pagination {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		&.has-unique-title {
			padding-top: 0;
		}

		.content-block__title-container {
			color: #000;
			background: #fff;
			text-align: center;
			padding: 0 0 rem(51px);
			margin: 0 0 rem(80px);

			.title {
				margin: 0;
			}
		}

		.link {
			@include font-button;
			bottom: rem(158px);
			left: 50%;
			transform: translateX(-50%);
			font-weight: 800;
			font-style: italic;
			text-transform: uppercase;
			@include underline-effect-base(#fff, -2px);
			position: absolute;

			&:hover {
				@include underline-effect-hover();
			}
		}

		.fader__link-container {
			@include fade-in-up();
		}
	}

	&.is-specs-block {
		@extend %background-lines;
		overflow: hidden;
		text-align: center;

		.sub-title {
			text-transform: none;
		}

		.content {
			text-align: left;
		}

		.column {
			@include fade-in-up;
		}

		.button {
			@include fade-in-up;
		}

		@include breakpoint(l) {

			.column:first-child {
				@include fade-in-right;
			}

			.column:last-child {
				@include fade-in-left;
			}
		}
	}
}

.child-navigation + .content-block.is-text-block {

	@include breakpoint(l) {
		padding: rem(221px) 0 rem(102px);
	}
}
