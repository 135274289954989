﻿.title {
	@include font-title;
	text-transform: uppercase;
	font-weight: 800;

	span {
		display: block;
	}

	&.is-smaller {
		@include font-header-title;
	}

	&.is-small {
		@include font-title-small;
	}
}

.sub-title {
	@include font-sub-title;
	font-weight: 800;
	text-transform: uppercase;
}
