﻿.container {
	width: 100%;
	max-width: rem(1430px);
	padding: 0 rem(20px);
	margin: 0 auto;

	&.is-bigger {
		max-width: rem(1720px);
	}

	&.is-smaller {
		max-width: rem(1192px);
	}

	&.is-slide {
		max-width: rem(902px);
		padding: 0 rem(40px);

		@include breakpoint(t) {
			padding: 0 rem(60px);
		}

		@include breakpoint(l) {
			padding: 0 rem(80px);
		}
	}
}
