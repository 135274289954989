﻿.child-navigation {
	position: relative;
	z-index: 1;
	padding: rem(40px) 0;
	background: #000;
	color: #fff;
	font-weight: bold;
	display: flex;

	.child-navigation__container {
		display: flex;
		margin: 0 auto;
	}

	.child-navigation__prev,
	.child-navigation__next,
	.child-navigation__parent {
		position: relative;
		display: block;
		width: rem(60px);
		height: rem(60px);
		border: rem(2px) solid #fff;
		border-radius: 50%;
		margin: 0 rem(10px);
		text-align: center;
		opacity: 0.2;
		transition: opacity $transition-time ease;

		i {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&:hover {
			opacity: 0.5;
		}
	}

	.child-navigation__prev,
	.child-navigation__next {
		padding: rem(10px) 0;

		i {
			font-size: rem(11px);
			height: rem(11px);
			line-height: rem(11px);

			@include breakpoint("t") {
				//top: rem(-14px);
			}
		}
	}

	.child-navigation__prev {

		i {
			margin: rem(-3px) 0 0 rem(-2px);
		}
	}

	.child-navigation__next {

		i {
			margin: rem(-3px) 0 rem(-2px) 0;
		}
	}

	.child-navigation__parent {

		i {
			//position: relative;
			font-size: rem(20px);
			transition: color $transition-time ease-in-out;
			margin: rem(-2px) 0 0;
			line-height: rem(20px);

			@include breakpoint(t) {
				//top: rem(-3px);
			}
		}
	}

	&:last-child {
		margin-top: rem(-58px);
	}
}

.header + .child-navigation {
	margin-bottom: rem(-75px);

	@include breakpoint(l) {
		margin-bottom: rem(-150px);
	}
}