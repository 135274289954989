﻿.logo {
	display: block;
	background-size: contain;
	overflow: hidden;
	text-indent: -99999px;

	&.is-brand {
		width: rem(173px);
		height: rem(56px);
		background-image: url(../../images/logo-blitz.svg);
		transition: opacity $transition-time ease-in-out;

		.headroom--not-top & {
			opacity: 0;
		}
	}
}
