﻿.content {

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	p,
	ol,
	table {
		margin: 0 0 rem(46px);
	}

	+ .button {
		margin-top: rem(2px);
	}

	.sub-title {
		margin: 0;
	}

	strong {
		font-weight: 700;
	}
}
