﻿.description {
	@include font-description;
	position: relative;

	.description__message {
		position: absolute;
		width: rem(400px);
		top: 4.25rem; // 68px
		left: rem(-140px);
		padding: rem(23px) rem(40px) rem(24px);
		background: $color-purple;
		color: #fff;
		font-style: italic;
		transition: opacity $transition-time ease-in-out;

		@include breakpoint(l) {
			position: absolute;
			top: 50%;
			left: 100%;
			transform: translateY(-50%);
		}

		&:before {
			position: absolute;
			content: '';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 rem(10px) rem(12px) rem(10px);
			border-color: transparent transparent $color-purple;
			top: rem(-11px);
			left: rem(162px);

			@include breakpoint(l) {
				top: 50%;
				left: rem(-12px);
				transform: translateY(-50%);
				border-width: rem(10px) rem(12px) rem(10px) 0;
				border-color: transparent $color-purple transparent transparent;
			}
		}

		a:hover {
			text-decoration: none;
		}

		.js & {
			opacity: 0;
			pointer-events: none;

			&.is-visible {
				opacity: 1;
				pointer-events: initial;
			}
		}
	}

	.description__close {
		position: absolute;
		top: rem(7px);
		right: rem(10px);
		font-size: rem(11px);
		line-height: rem(11px);
		padding: rem(5px);
		cursor: pointer;

		&:before {
			@include icon($icon-close);
		}
	}
}
