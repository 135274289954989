﻿.social-media {
	overflow: hidden;
	list-style: none;
	padding: 0 0 rem(14px);
	text-align: left;

	li {
		float: left;
		margin: 0 rem(10px);
	}

	a {
		transition: color $transition-time ease-in-out;

		&:hover {
			color: $color-purple;
		}
	}

	i {

		&:before {
			font-size: rem(20px);
		}

		&.icon-youtube {
			font-size: rem(24px);
		}
	}
}
