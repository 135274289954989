﻿// Animations variables
$animation-distance: rem(100px) !default;
$animation-speed: 0.5s;

@mixin fade-in($delay: 0s) {
	@at-root .js#{&} {
		opacity: 0;
	}

	@at-root .js .animate#{&} {
		animation: fade-in $animation-speed ease $delay 1 both;
	}
}

@mixin fade-in-up($delay: 0s) {
	@at-root .js#{&} {
		opacity: 0;
	}

	@at-root .js .animate#{&} {
		animation: fade-in-up $animation-speed ease $delay 1 both;
	}
}

@mixin fade-in-down($delay: 0s) {
	@at-root .js#{&} {
		opacity: 0;
	}

	@at-root .js .animate#{&} {
		animation: fade-in-down $animation-speed ease $delay 1 both;
	}
}

@mixin fade-in-right($delay: 0s) {
	@at-root .js#{&} {
		opacity: 0;
	}

	@at-root .js .animate#{&} {
		animation: fade-in-right $animation-speed ease $delay 1 both;
	}
}

@mixin fade-in-left($delay: 0s) {
	@at-root .js#{&} {
		opacity: 0;
	}

	@at-root .js .animate#{&} {
		animation: fade-in-left $animation-speed ease $delay 1 both;
	}
}

@keyframes fade-in { 

	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fade-in-up {

	0% {
		opacity: 0;
		transform: translateY($animation-distance);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade-in-right {

	0% {
		opacity: 0;
		transform: translateX(-$animation-distance);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fade-in-down {

	0% {
		opacity: 0;
		transform: translateY(-$animation-distance);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fade-in-left {

	0% {
		opacity: 0;
		transform: translateX($animation-distance);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}