﻿@mixin underline-effect-base($background-color, $bottom: 0, $height: 2px) {
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: rem($height);
		bottom: rem($bottom);
		left: 0;
		background: $background-color;
		transform: scaleX(0);
		transition: transform $transition-time ease-in-out;
		transform-origin: right;
	}
}

@mixin underline-effect-hover() {

	&:before {
		transform: scaleX(1);
		transform-origin: left;
	}
}