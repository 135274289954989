﻿.legal {
	@include font-legal;
	font-weight: 500;
	display: flex;
	flex-wrap: wrap;
	padding: rem(9px) 0 rem(11px);
	margin: rem(-12px) rem(-12px) rem(-9px);
	list-style: none;
	color: #fff;

	a {
		@include underline-effect-base(#fff, -2px, 1px);

		&:hover {
			@include underline-effect-hover();
		}
	}
}
