﻿%background-lines {

	&:after {
		z-index: -1;
		position: absolute;
		content: '';
		width: rem(2350px);
		height: rem(2145px);
		top: rem(-180px);
		left: 50%;
		transform: translateX(-50%);
		background: url(../../images/lines-thin-purple.png) center;
		background-size: rem(2350px) rem(2145px);
		transition: opacity 0.2s ease 0.2s;

		@include breakpoint(d) {
			width: rem(2560px);
			height: rem(2337px);
			background-size: rem(2560px) rem(2337px);
		}
	}

	.has-animation:after & {
		opacity: 0;
	}

	.animate:after & {
		opacity: 1;
	}
}

%background-lines-colored {
	@extend %background-lines;
	background-image: url(../../images/lines-thin-colored.png);
}