﻿.cases-overview {
	@include colored-background("../../images/header-background/header-background", false, center top);
	position: relative;
	z-index: 0;
	padding: rem(230px) 0 rem(122px);
	text-align: center;

	.title {
		@include fade-in-up();
		margin: 0 0 rem(39px);
	}

	.content {
		@include fade-in-up(0.25s);
	}

	.social-media {
		position: absolute;
		z-index: 1;
		left: rem(10px);
		bottom: 0;

		@include breakpoint(l) {
			right: rem(30px);
			bottom: rem(20px);
			left: auto;
		}

		@for $i from 1 through 10 {

			li:nth-child(#{$i}) {
				animation: fade-in-up $animation-speed ease ($i * 0.08s + 0.25s) 1 both;
			}
		}
	}
}