﻿.slider {
	@include fade-in-down;

	.swiper-slide {
		width: 100%;
		max-width: rem(800px);

		&.swiper-slide-active {

			.image {
				opacity: 1;
			}
		}
	}
}

.slide {
	position: relative;
	padding: 0 0 rem(48px);

	@include breakpoint(t) {
		//margin: 0 rem(-32px);
		border-left: solid rem(20px) transparent;
		border-right: solid rem(20px) transparent;
	}

	@include breakpoint(l) {
		border-left: solid rem(48px) transparent;
		border-right: solid rem(48px) transparent;
	}

	a {

		&:hover {

			.slide__content {
				opacity: 0.85;

				@include breakpoint(l) {
					transform: scale(1.1) skew(-10deg);
				}

				&:before {
					transform: scaleX(1);
				}
			}
		}
	}

	.slide__content {
		position: absolute;
		max-width: calc(100% - 20px);
		width: 100%;
		padding: rem(26px) rem(64px) rem(32px) rem(32px);
		bottom: 0;
		left: 0;
		opacity: 0.9;
		background: linear-gradient(270deg, $color-purple 0%, $color-pink 100%);
		clip-path: polygon(0 0, 0 100%, calc(100% - 44px) 100%, 100% 0);
		transition: transform $transition-time ease, opacity $transition-time ease;
		transform: scale(1);
		transform-origin: center;

		@include breakpoint(t) {
			max-width: calc(100% - 50px);
			padding: rem(66px) rem(80px) rem(62px);
			left: rem(-40px);
			transform: scale(1) skew(-10deg);
			clip-path: none;
		}

		@include breakpoint(l) {
			max-width: calc(100% - 100px);
			padding: rem(66px) rem(100px) rem(92px);
			left: rem(-90px);
		}

		&:before {
			position: absolute;
			z-index: -1;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transform: scaleX(0);
			transform-origin: left;
			background: $color-pink;
			transition: transform $transition-time ease;

			@include breakpoint(l) {
				transform: scaleX(0) skew(-10deg);
			}
		}
	}

	.slide__inner-content {

		@include breakpoint(t) {
			transform: skew(10deg);
		}
	}

	.title,
	.caption {
		@include font-sub-title;
		text-transform: uppercase;
		font-weight: 800;


		@include breakpoint(l) {
			@include font-slide;
		}
	}

	.title {
		margin: 0;
	}

	.caption {
		display: block;
	}

	.image {
		opacity: 0.5;
		transition: opacity $transition-time ease;
		transform-origin: center;

		img {
			transform-origin: center;
			max-width: initial;
			width: 100%;

			@include breakpoint(t) {
				transform: skew(10deg);
				width: 126%;
				margin-left: -13%;
			}
		}

		@include breakpoint(t) {
			transform: skew(-10deg);
		}
	}

	.slide__content-logo {
		margin: rem(16px) 0 0;
	}
}
