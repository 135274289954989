﻿.header {
	position: relative;
	min-height: 100vh;
	z-index: 2;

	.js {
		min-height: var(--hh);
	}

	.header__image {
		position: absolute;
		z-index: -2;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.title-background {
		padding: 0 rem(20px) 0 0;
	}

	.header__title,
	.title-background {
		position: absolute;
		z-index: 2;
		bottom: rem(120px);
		left: rem(20px);

		@include breakpoint(l) {
			left: rem(60px);
		}

		.sub-title {
			@include fade-in-right(0.25s);
		}

		.header__title {
			position: static;

			span {
				@include fade-in-right(0.50s);
			}
		}
	}

	.header__title {
		@include font-header-title;
		z-index: 2;
		text-transform: uppercase;
	}

	.video {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;

		&.is-full-video {
			z-index: -1;
			opacity: 0;
			transition: opacity $transition-time ease;

			@media (hover: hover) {
				cursor: url("../../images/cursor-play-button.png"), auto;
			}

			&.is-active {
				opacity: 1;

				@media (hover: hover) {

					&.is-playing {
						cursor: url("../../images/cursor-pauze-button.png"), auto;
					}

					&.is-paused {
						cursor: url("../../images/cursor-play-button.png"), auto;
					}
				}
			}
		}
	}

	.video-wrapper:before {
		position: absolute;
		z-index: -2;
		content: '';
		width: rem(128px);
		height: rem(47px);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: url(../../images/cursor-play-button.png);
		background-size: contain;
		pointer-events: none;
		opacity: 0;
		animation: fade-in 0.5s ease 0.5s 1 both;

		@media (hover: hover) {
			display: none;
		}
	}

	.social-media {
		position: absolute;
		z-index: 2;
		left: rem(10px);
		bottom: rem(60px);

		@for $i from 1 through 10 {

			li:nth-child(#{$i}) {
				@include fade-in-up((0.15s * $i + 0.25s))
			}
		}

		@include breakpoint(l) {
			right: rem(30px);
			bottom: rem(20px);
			left: auto;
		}
	}
}
