﻿#side-pop-up {
	display: none;

	&:checked + .side-pop-up-toggler {
		@extend .is-active;
	}

	&:checked ~ .side-pop-up {
		transform: translateX(0);

		&:before {
			pointer-events: initial;
		}

		@for $i from 1 through 10 {

			.nav__item:nth-child(#{$i}) {
				animation: fade-in-left $animation-speed ease ($i * 0.08s) 1 both;
			}
		}

		.contact-information {
			animation: fade-in-left $animation-speed ease 0.48s 1 both;

			@include breakpoint(l) {
				//animation: fade-in-right $animation-speed ease 1s 1 both;
			}
		}
	}
}

.side-pop-up {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100vh;
	top: 0;
	right: 0;
	transform: translateX(100%);
	background: linear-gradient(135deg, $color-purple 10%, $color-pink 100%);
	color: #fff;
	transition: all $transition-time ease-in-out;
	@extend %background-lines;
	overflow: hidden;

	.side-pop-up__container {
		overflow-x: hidden;
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		height: 100%;
		list-style: none;
		padding: 0;
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		padding-top: rem(113px);
		padding-bottom: rem(80px);
	}

	.contact-information {

		@include breakpoint(l) {
			order: -1;
			margin-top: auto;
		}
	}
}