﻿%title-background,
.title-background {
	overflow: hidden;

	.title {
		display: inline;
		background: #fff;
		margin: 0;
	}

	.title span {
		display: block;
		float: left;
		clear: left;
		box-decoration-break: clone;
		padding: 0 rem(5px);
		background: #fff;
		margin-top: -1px;

		@include breakpoint(t) {
			padding: 0 rem(10px);
		}
	}

	.sub-title {
		position: relative;
		z-index: 1;
		display: block;
		float: left;
		clear: left;
		background: #fff;
		color: #000;
	}

	.sub-title {
		padding: rem(11px) rem(10px) rem(10px);

		@include breakpoint(t) {
			padding: rem(11px) rem(17px) rem(10px);
		}
	}

	&.is-black,
	&.title-background-is-black {

		.title span {
			background: #000;
			color: #fff;
		}

		.sub-title {
			background: #000;
			color: #fff;
		}
	}

	&.is-small {

		.title span {
			padding: 0 rem(5px);

			@include breakpoint(t) {
				padding: 0 rem(5px);
			}
		}

		.sub-title {
			padding: rem(0px) rem(4px) rem(0px);
		}

		@include breakpoint(t) {
			padding: rem(0px) rem(4px);

			.sub-title {
				padding: rem(0px) rem(4px) rem(0px);
			}
		}
	}
}
