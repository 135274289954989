﻿@import "fonts";
@import "icon";

$base-font-family: "Montserrat", sans-serif;

$color-text: #fff;
$color-pink: #E31A82;
$color-purple: #5300C9;
$color-toggler: #0d0629;

$transition-time: 0.3s;

$border-radius: rem(28px);