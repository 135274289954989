﻿.button {
	@include font-button;
	position: relative;
	z-index: 0;
	display: inline-flex;
	height: rem(48px);
	padding: rem(16px) rem(28px) rem(16px) rem(28px);
	border: none;
	border-radius: $border-radius;
	margin: 0;
	text-decoration: none;
	color: #fff;
	background: $color-pink;
	overflow: hidden;
	cursor: pointer;
	text-transform: uppercase;
	font-style: italic;
	font-weight: 800;
	transition: background $transition-time ease, color $transition-time ease;

	&:before {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(270deg, $color-purple 0%, $color-pink 100%);
		transition: transform $transition-time ease-in-out;
		transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
		transform: scaleX(1);
		transform-origin: right;
	}

	&.is-white {
		border: 2px solid #fff;
		background: transparent;
		color: #fff;
		padding: rem(13px) rem(28px) rem(13px) rem(28px);

		&:before {
			background: #fff;
			transform: translateX(-100%);
		}
	}

	&.is-play {
		padding-right: rem(50px);

		&:after {
			@include icon($icon-play);
			position: absolute;
			font-size: rem(12px);
			top: rem(18px);
			right: rem(28px);
		}
	}

	&.is-information {
		min-height: rem(48px);
		min-width: rem(48px);
		padding: 0;
		background: #fff;
		color: $color-purple;

		&:before {
			transform: scale(0);
			transform-origin: center;
			border-radius: 50%;
		}

		&:after {
			@include icon($icon-information);
			position: absolute;
			font-size: rem(26px);
			margin-left: 1px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&.is-loading {
		padding-left: 60px;

		&:after {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-pink;
			border-radius: 50%;
			border-top: rem(4px) solid lighten($color-pink, 10%);
			width: rem(20px);
			height: rem(20px);
			top: rem(16px);
			left: rem(16px);
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	&:focus {
		@extend .button-focus;
	}

	&:hover {
		@extend .button-hover;
	}
}

.button-focus {

	&.is-white {
		background: #fff;
		color: #000;
	}
}

.button-hover {

	&:before {
		transform: scaleX(0);
	}

	&.is-information {
		color: #fff;

		&:before {
			transform: scale(1);
		}
	}

	&.is-white {
		//background: #fff;
		color: $color-purple;

		&:before {
			transform: translateX(0);
		}
	}
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	margin: rem(-10px);

	.button,
	.video-link {
		margin: rem(10px);
	}
}
