﻿.swiper-pagination {

	.swiper-pagination-bullet {
		background: #fff;
		width: rem(24px);
		height: rem(5px);
		border-radius: 0;
		margin: 0 rem(5px);
		clip-path: polygon(2px 0, 0 100%, calc(100% - 2px) 100%, 100% 0);
		transition: opacity $transition-time ease;

		&:hover {
			opacity: .6;
		}
	}
}