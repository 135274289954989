﻿.hero {
	position: relative;
	min-height: 100vh;
	z-index: 0;
	overflow: hidden;

	.js & {
		min-height: var(--hh);
	}

	.hero__image {
		position: absolute;
		z-index: -4;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	.hero__scroll-down {
		position: absolute;
		width: rem(64px);
		height: rem(64px);
		bottom: rem(100px);
		left: 50%;
		margin-left: rem(-32px);
		//transform: translateX(-50%);
		animation: scroll-down-animation 1.5s ease infinite;

		@include breakpoint(l) {
			bottom: rem(20px);
		}

		&:before {
			@include icon($icon-arrow-down);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(11px);
		}
	}

	@keyframes scroll-down-animation {

		0% {
			opacity: 1;
			transform: translateY(0);
		}

		100% {
			opacity: 0;
			transform: translateY(40px);
		}
	}

	.video {
		position: fixed;
		z-index: -3;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;

		&.is-full-video {
			z-index: -1;
			opacity: 0;
			transition: opacity $transition-time ease;

			@media (hover: hover) {
				cursor: url("../../images/cursor-play-button.png"), auto;
			}

			&.is-active {
				opacity: 1;

				@media (hover: hover) {

					&.is-playing {
						cursor: url("../../images/cursor-pauze-button.png"), auto;
					}

					&.is-paused {
						cursor: url("../../images/cursor-play-button.png"), auto;
					}
				}
			}

			&.is-mobile-version {

				@include breakpoint(l) {
					display: none;
				}

				+ .video.is-full-video {
					display: none;

					@include breakpoint(l) {
						display: block;
					}
				}
			}
		}
	}

	.video-wrapper:before {
		position: absolute;
		z-index: -2;
		content: '';
		width: rem(128px);
		height: rem(47px);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: url(../../images/cursor-play-button.png);
		background-size: contain;
		pointer-events: none;
		opacity: 0;
		animation: fade-in 0.5s ease 0.5s 1 both;

		@media (hover: hover) {
			display: none;
		}
	}

	.social-media {
		position: absolute;
		z-index: 1;
		right: rem(30px);
		bottom: rem(80px);

		@for $i from 1 through 10 {

			li:nth-child(#{$i}) {
				@include fade-in-up((0.15s * $i + 1s))
			}
		}

		@include breakpoint(l) {
			bottom: rem(20px);
		}
	}
}
