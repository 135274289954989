﻿.nav.is-header-nav {
	@include font-header-menu;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	text-transform: uppercase;
	font-weight: bold;
	font-style: italic;
	color: #fff;
	opacity: 1;
	transition: opacity $transition-time ease-in-out;

	.headroom--not-top & {
		opacity: 0;
	}

	.nav-container {
		display: flex;

		@include breakpoint(l) {
			padding: 0 rem(21px);
		}

		&:nth-child(1) {
			transform: translateX(-100px);
		}

		&:nth-child(2) {
			transform: translateX(100px);
		}

		.home & {

			&:nth-child(1) {
				animation-name: header-nav-animation-left;
				animation-duration: 1s;
				animation-fill-mode: forwards;
			}

			&:nth-child(2) {
				animation-name: header-nav-animation-right;
				animation-duration: 1s;
				animation-fill-mode: forwards;
			}
		}
	}

	.nav-item {
		margin: 0 rem(32px);
		padding: 6px 0;

		&.is-active a {

			@include underline-effect-hover()
		}
	}

	a {
		@include underline-effect-base(#fff, -6px, 2px);
		position: relative;
		text-decoration: none;

		&:hover {
			@include underline-effect-hover()
		}
	}

	@keyframes header-nav-animation-left {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(-100px);
		}
	}

	@keyframes header-nav-animation-right {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(100px);
		}
	}
}
